
import { API_URL } from "@/providers/constants";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonSpinner,
  IonSearchbar,
  toastController,
  IonBackButton,
  IonButtons,
  IonCardSubtitle,
  IonCard,
  IonCardHeader,
  IonCardContent,
} from "@ionic/vue";
import { defineComponent } from "vue";
import axios from "axios";
import { Storage } from "@capacitor/storage";

export default defineComponent({
  name: "Login",
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButton,
    IonSpinner,
    IonSearchbar,
    IonBackButton,
    IonButtons,
    IonCardSubtitle,
    IonCard,
    IonCardHeader,
    IonCardContent,
  },
  data() {
    return {
      username: "",
      password: "",
      responseStatus: "",
      loading: false,
      search: "",
      productUrl: "",
      products: {},
      token: "",
    };
  },
  mounted() {
    this.getToken();
  },
  methods: {
    searchProducts() {
      this.loading = true;

      this.productUrl =
        API_URL +
        "/rest/V1/products?searchCriteria[filterGroups][0][filters][0][field]=name&searchCriteria[filterGroups][0][filters][0][value]=%25" +
        this.search +
        "%25&searchCriteria[filterGroups][0][filters][0][condition_type]=like&searchCriteria[filterGroups][0][filters][1][field]=sku&searchCriteria[filterGroups][0][filters][1][value]=%25" +
        this.search +
        "%25&searchCriteria[filterGroups][0][filters][1][condition_type]=like";

      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.token,
      };
      axios
        .get(this.productUrl, { headers })
        .then((response) => {
          if (response.data.items.length) this.products = response.data.items;
          else
            this.openToast(
              "No items with name or SKU " + this.search + " were found"
            );
          this.loading = false;
        })
        .catch((error) => {
          if (error.response)
            this.openToast(
              "Session timeout, please exit the app and login again"
            );
          this.loading = false;
        });
    },
    editProduct(product) {
      this.$router.push({
        name: "Edit",
        params: { productSku: product, token: this.token },
      });
    },
    async openToast(message) {
      const toast = await toastController.create({
        message: message,
        duration: 2000,
      });
      return toast.present();
    },
    async getToken() {
      const { value } = await Storage.get({ key: "token" });
      this.token = value;
    },
  },
});
