<template>
  <ion-page>
    <ion-header :translucent="true" class="ion-no-border">
      <ion-toolbar>
        <ion-buttons>
          <ion-back-button></ion-back-button>
        </ion-buttons>
        <ion-title>Create Product</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <div id="container">
        <div id="create-container">
          <ion-searchbar
            show-cancel-button="focus"
            v-model="search"
            cancel-button-text="Cancel"
          ></ion-searchbar>
          <ion-button
            @click="searchProducts()"
            id="create-button"
            expand="block"
            >Search <ion-spinner name="lines-small" v-if="loading"></ion-spinner
          ></ion-button>
          <ion-card
            v-for="product in products"
            :key="product.id"
            @click="editProduct(product.sku)"
          >
            <ion-card-header class="productHeader">
              <ion-card-subtitle>Price - {{ product.price }}</ion-card-subtitle>
              <ion-card-subtitle>{{ product.name }}</ion-card-subtitle>
            </ion-card-header>

            <ion-card-content>
              {{ product.description }}
            </ion-card-content>
          </ion-card>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { API_URL } from "@/providers/constants";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonSpinner,
  IonSearchbar,
  toastController,
  IonBackButton,
  IonButtons,
  IonCardSubtitle,
  IonCard,
  IonCardHeader,
  IonCardContent,
} from "@ionic/vue";
import { defineComponent } from "vue";
import axios from "axios";
import { Storage } from "@capacitor/storage";

export default defineComponent({
  name: "Login",
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButton,
    IonSpinner,
    IonSearchbar,
    IonBackButton,
    IonButtons,
    IonCardSubtitle,
    IonCard,
    IonCardHeader,
    IonCardContent,
  },
  data() {
    return {
      username: "",
      password: "",
      responseStatus: "",
      loading: false,
      search: "",
      productUrl: "",
      products: {},
      token: "",
    };
  },
  mounted() {
    this.getToken();
  },
  methods: {
    searchProducts() {
      this.loading = true;

      this.productUrl =
        API_URL +
        "/rest/V1/products?searchCriteria[filterGroups][0][filters][0][field]=name&searchCriteria[filterGroups][0][filters][0][value]=%25" +
        this.search +
        "%25&searchCriteria[filterGroups][0][filters][0][condition_type]=like&searchCriteria[filterGroups][0][filters][1][field]=sku&searchCriteria[filterGroups][0][filters][1][value]=%25" +
        this.search +
        "%25&searchCriteria[filterGroups][0][filters][1][condition_type]=like";

      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.token,
      };
      axios
        .get(this.productUrl, { headers })
        .then((response) => {
          if (response.data.items.length) this.products = response.data.items;
          else
            this.openToast(
              "No items with name or SKU " + this.search + " were found"
            );
          this.loading = false;
        })
        .catch((error) => {
          if (error.response)
            this.openToast(
              "Session timeout, please exit the app and login again"
            );
          this.loading = false;
        });
    },
    editProduct(product) {
      this.$router.push({
        name: "Edit",
        params: { productSku: product, token: this.token },
      });
    },
    async openToast(message) {
      const toast = await toastController.create({
        message: message,
        duration: 2000,
      });
      return toast.present();
    },
    async getToken() {
      const { value } = await Storage.get({ key: "token" });
      this.token = value;
    },
  },
});
</script>
<style scoped>
#container {
  text-align: center;

  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  padding: 16px;
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;

  color: #8c8c8c;

  margin: 0;
}

#container a {
  text-decoration: none;
}
#logo {
  margin-bottom: 40px;
}
#login-container {
  margin-bottom: 20px;
}
#info {
  font-size: 10px;
}
#login-button {
  margin-top: 20px;
  margin-bottom: 20px;
}
#productsList {
  margin-top: 20px;
}
#create-button {
  margin: 10px 0;
}
.productHeader {
  text-align: left;
}
</style>